/*--------------------------------------------------------------------------------------*/
/*General-Css-Here
/*--------------------------------------------------------------------------------------*/

:root {
  --light-yellow:#FFEBD0;
  --light-yellow2:#FFFCF9;
  --black:#000;
  --white:#fff;  
  --yellow:#F39B1D;
  --heading:#230709;
  --p:#62595A;
  --gray:#C0C0C0;
}

*{margin: 0; padding: 0; box-sizing: border-box;} 
body,html{font-size: 16px; color:var(--black); font-family: "Plus Jakarta Sans", sans-serif; font-weight: 400; font-style: normal; box-sizing: border-box;}
html{scroll-padding-top: 10rem;}
a{text-decoration: none; transition-duration: 0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s; color:var(--sky-blue);}
button{transition-duration: 0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
ul{margin: 0; padding: 0; list-style: none;}
img{max-width: 100%;}
.paddTop0{padding-top: 0 !important;}
.btn-group-box .btn{margin-right: 15px;}
.btn-group-box .btn:last-child{margin-right: 0;}
.section-padding{padding:100px 0px;
  @media only screen and (max-width: 1280px) {
    padding: 70px 0px;
  }
  @media only screen and (max-width: 768px) {
   padding: 50px 0px;
  }

}
.white{color: var(--white);}
.btn{height: 46px; line-height: 46px; padding:0px 25px; font-size: 15px; font-family: var(--medium); border: none; border-radius:4px; background:var(--yellow); color: var(--white); font-weight: 600;}
.btn:hover{background: var(--black); color: var(--white);}

h1 { font-size:34px; }
h2 { font-size:28px }
h3 { font-size:26px; }
h4 { font-size:24px; }
h5 { font-size:20px; }
h6 { font-size:18px; }

p{color: var(--p); font-size: 16px; line-height: 28px;
  @media only screen and (max-width: 570px) {
    font-size: 14px;
    line-height: 24px;
   }
}

.transform-scale-07{transform: scale(0.7);}
.transform-scale-08{transform: scale(0.8);}
.transform-scale-09{transform: scale(0.9);}
.transform-scale-1{transform: scale(1);}
.transform-scale-1-1{transform: scale(1.1);}
.transform-scale-1-2{transform: scale(1.2);}
.transform-scale-1-3{transform: scale(1.3);}
.transform-scale-1-4{transform: scale(1.4);}
.transform-scale-1-5{transform: scale(1.5);}
.mr-bot0{margin-bottom: 0 !important;}
.border0{border: none !important;}
.padd-left-35{padding-left: 35px;}
.mar-top50{margin-top: 50px;}
.mar-bot50{margin-bottom: 50px;}
.mar-top80{margin-top: 80px;}
.mar-left-right50{margin-left: 50px; margin-right:50px;}
.mar-left-right80{margin-left: 80px; margin-right:80px;}
.mar-top-bottom50{margin-top: 50px; margin-bottom:50px;}
.mar-top-bottom30{margin-top: 30px; margin-bottom:30px;}
.padd-top-bottom50{padding-top: 50px; padding-bottom:50px;}
.mr-left-right45{margin-left: 45px; margin-right: 45px;}
.padd0{padding: 0 !important;}
.mr0{margin: 0 !important;}
.padd-top89{padding-top: 89px !important;}
hr{margin: 25px 0px;}
.padd20{padding: 20px;}
.padd30{padding: 30px;}
.padd-bot0{padding-bottom: 0;}
.ml-50{margin-left: 50px}

.white-bg{background:var(--white); border: 1px solid var(--border); border-radius: 30px;}
.light-bg{background: var(--white) !important;}
.marTop40{margin-top: 40px;}
.form-check-input:checked{background-color: var(--brown); border-color: var(--brown);}
/* .site-bg{padding-top: 88px;} */

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/*--------------------------------------------------------------------------------------*/
/*header-css-here
/*--------------------------------------------------------------------------------------*/


header{position: fixed; left: 0; top: 0; width: 100%; z-index: 99; padding: 15px 0px; background: #ffeed4;}
.header-inner{display: grid; grid-template-columns:repeat(3,1fr); align-items: center;
  @media only screen and (max-width: 1280px) {
    grid-template-columns: 1fr auto 1fr;
  }
}
.head-right{margin-left: auto;
  @media only screen and (max-width: 991px){
    margin-right: 70px;
  }
  .contact-btn{
   .btn{
    @media only screen and (max-width:479px){
     padding: 0px 10px;
    }
   } 
  }
}
.logo{
  a{
    @media only screen and (max-width:479px){
      display: block;
      max-width: 150px;
    }
  }
}



#navbarNavDropdown{justify-content: center;
  @media only screen and (max-width: 991px){
   position: absolute;
   left: 0;
   width: 100%;
   top: 92px;
   background: var(--light-yellow);
  }

}
.fixed header{background:var(--light-yellow);}
.header-inner{
  & nav{position: inherit;
    & ul{
      & li{
        & a{
          color: var(--heading); font-size: 15px; font-weight: 600; padding-left: 10px !important; padding-right: 10px !important;
          &:hover{color: var(--yellow);}
      }
      & .active{color: var(--yellow) !important;}
    }
  }
 } 
 
 .navbar-toggler{
  @media only screen and (max-width: 991px){
   position: absolute;
   right: 10px;
   top: 23px;
   height: 46px;
   background: var(--yellow); 
   border-color: var(--yellow);
   span{color: var(--white);}
  }
  @media only screen and (max-width:479px){
   top: 14px;
  }
}




}

/*--------------------------------------------------------------------------------------*/
/*home-page-css-here
/*--------------------------------------------------------------------------------------*/

.slider-section{background: linear-gradient(180deg, #FFEBCD 0%, #FFFFFF 100%); background: linear-gradient(180deg, color(display-p3 1.000 0.925 0.816) 0%, color(display-p3 1.000 1.000 1.000) 100%); min-height: 100vh; padding: 145px 0px 0px 0px; position: relative;
.shape-box01{position: absolute; left: 0; bottom: 100px;
  @media only screen and (max-width: 1280px) {
    display: none;
  }
}
.shape-box02{position: absolute; right: 0; bottom: 0px;
  @media only screen and (max-width: 1280px) {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  min-height: auto;
}

@media only screen and (max-width: 570px) {
 padding-top: 92px;
}

}
.slider-des-box{position: relative; z-index: 1;}
.slider-des-row{display: grid; grid-template-columns: 1fr 1fr; gap: 30px; align-items: center;
 & h1{color: var(--heading); font-size: 62px; font-weight: 800; line-height: normal; margin-bottom: 15px;
   span{color: var(--yellow);}
   @media only screen and (max-width: 1280px) {
     font-size: 40px;
   }

   @media only screen and (max-width: 570px) {
    font-size: 32px;
   }

   @media only screen and (max-width: 479px) {
    font-size: 26px;
   }
}

@media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
 }
}
.device-image-box{text-align: center;}
.about-image{text-align: center;}


.app-download-btn{display: flex; gap: 15px; margin-top: 30px;}
.about-section{background: url(../images/about-shape.png) no-repeat left bottom;
  @media only screen and (max-width: 1023px){
   background: none; 
  }
}
.about-section-grid{display: grid; grid-template-columns:1fr 1fr; gap: 30px;
 @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
 }
 .section-heading{
  @media only screen and (max-width: 768px) {
   margin-bottom: 0; 
  }
 }

}
.section-heading{margin-bottom: 40px;
 h5{font-size: 15px; color: var(--p); display: flex; align-items: center;
 img{width: 11px; height: 11px; margin-right: 10px;}
} 
h2{font-size: 44px; color: var(--heading); font-weight: 800; margin-bottom: 15px;
 span{color: var(--yellow);}
 @media only screen and (max-width: 1280px) {
  font-size: 32px;
  @media only screen and (max-width: 768px) {
   font-size: 28px;
  }
 }
}
p{margin: 0;}
}
.how-we-work-section{background: var(--light-yellow2);}
.grid-row-4{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 30px;
  @media only screen and (max-width: 768px) {
   grid-template-columns: 1fr 1fr; 
  }

  @media only screen and (max-width: 479px) {
    grid-template-columns: 1fr; 
  }
}
.number-box{position: absolute; right: 15px; top: 15px; font-weight: 400; font-size: 58px; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color:var(--yellow); color: var(--white);
  @media only screen and (max-width: 1280px) {
   font-size: 40px; 
  }

}  
.how-work-box{background: var(--white); background: color(display-p3 1.000 1.000 1.000); box-shadow: 0px 4px 14px rgba(255, 150, 0, 0.1); box-shadow: 0px 4px 14px color(display-p3 0.953 0.608 0.114 / 0.1); border-radius: 15px; padding:30px 15px; position: relative; text-align: center;
 figure{margin-top: 25px; margin-bottom: 20px;}
 figcaption{
  h4{font-size: 22px; color: var(--heading); font-weight: 600;
    @media only screen and (max-width: 1280px) {
      font-size: 18px;
    }
  }
  p{margin: 0;} 
 }
} 

.app-screenshot-section{background: url(../images/screenshot-shape.png) no-repeat left top; position: relative;}
.app-screenshot-row{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 15px;}
.app-screenshot-columns{text-align: center;}
.shape03{position: absolute; right: 0; bottom: 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.faq-section{background: var(--light-yellow2);}

.accordion-item{background: none; border: none; outline: none;
 .accordion-header{
  button{background: none; font-size: 22px; color: var(--heading); font-weight: 700; outline: none !important; box-shadow: none; border:none !important; padding-left: 0; padding-right: 0;
   &:focus{box-shadow: none; outline: none;}
   @media only screen and (max-width: 768px) {
    font-size: 18px;
   }
  }
  button[aria-expanded="true"]{color: var(--yellow);}
 }
.accordion-body{padding: 0;
 p{margin-bottom: 0;} 
}
}

/*--------------------------------------------------------------------------------------*/
/*footer-css-here
/*--------------------------------------------------------------------------------------*/

footer{background: var(--white);}
.top-footer{padding: 25px 0px;}
.follow-box{display: flex; align-items: center; border-bottom: solid 1px var(--gray); padding-bottom: 20px;
 h5{font-size: 14px; color: var(--heading); font-weight: 600; margin-bottom: 0; margin-right: 15px;}
 ul{display: flex; align-items: center;
  li{margin-right: 10px;
   &:last-child{margin-right: 0;} 
  }
 }
 @media only screen and (max-width: 570px) {
  justify-content: center;
 }
}
.bottom-footer{padding-bottom: 25px;}
.bottom-footer-inner{display: flex; justify-content: space-between; flex-wrap: wrap;}
.footer-menu{display: flex;
  li{margin-right: 10px;
    a{color: var(--heading); font-size: 14px; font-weight: 600;}
    &:last-child{margin-right: 0;}
  }
  @media only screen and (max-width: 570px) {
   flex-wrap: wrap;
   justify-content: center;
   width: 100%; 
  }

}
.copyright-box{margin: 0; font-size: 14px; color: var(--heading); font-weight: 600;
  @media only screen and (max-width: 570px) {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}




